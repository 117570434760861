@import './reset.scss';

@keyframes slide {
  0% {
    filter: blur(32px) saturate(300%);
    background-position: left 0;
  }
  50% {
    filter: blur(50px) saturate(300%);
    background-position: left -300px;
  }
  100% {
    filter: blur(32px) saturate(300%);
    background-position: left -600px;
  }
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #ddd;
}

::-webkit-scrollbar-thumb {
  background: #666;
}

$gray: #d1d1d1;
$black: #111;
$border-gray: #e5e5e5;
$wolf-gray: #919191;
$another-gray: #b3b3b3;
$about-gray: #e0e0e0;
$ease-std: cubic-bezier(0.4, 0, 0.2, 1);

$f1: 14.76px;
$f2: 13px;
$f3: 10.66px;

* {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

@keyframes rotato {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

html {
  height: 100%;
  color: $black;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 16px;
}
body {
  height: 100%;
}
#root,
.app {
  height: 100%;
}
.logo {
  cursor: pointer;
  position: absolute;
  top: calc(calc(50% - 376px / 2) / 2);
  left: 50%;
  margin-top: -17px;
  margin-left: -60px;
  width: (725px / 5 * 0.82);
  height: (86px / 5 * 0.82);
  background-size: contain;
  background-repeat: no-repeat;
  animation-fill-mode: forwards;
}
.stamp {
  position: absolute;
  bottom: 50%;
  right: 50%;
  margin-bottom: -195px;
  margin-right: -215px;
  width: 220px;
  height: 220px;
  background: url(../images/stamp.png) center center no-repeat;
  background-size: cover;
  z-index: -1;
  animation: rotato 20s linear infinite;
}
.carousel {
  opacity: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  transition: opacity 600ms $ease-std;
  &.visible {
    opacity: 1;
  }
}
.carousel-inner {
  margin-left: calc(50% - 99px);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 375ms $ease-std;
}
.product {
  margin-left: 90px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 375ms $ease-std;
  transform: translateZ(0);
  -webkit-tap-highlight-color: transparent;

  &:not(.focused) {
    opacity: 0.5;
    transform: scale(0.8);
  }
  &:first-child {
    margin-left: 0;
  }
  &.blurred {
    & .product-preview {
      overflow: hidden;
      border-radius: 14px;
    }
    & .product-disabled {
      cursor: default;
    }
    & .product-image {
      opacity: 0.5;
      animation: slide 4s infinite;
      animation-timing-function: linear;
      background-repeat: repeat-y;
      background-size: auto;
      &.front,
      &.first,
      &.second {
        display: none;
      }
    }
  }
  &.focused {
    & .product-quantity {
      margin-top: 20px;
      opacity: 1;
    }
  }
  &.focused.blurred {
    cursor: default;
  }
  &.focused.hoverable:hover {
    & .product-image {
      &.front {
        transform: translateY(-4px) translateX(84px) rotateZ(16deg);
        box-shadow: 0 2px 5px rgba($gray, 0.5);
      }
      &.first {
        transform: translateY(-4px) translateX(-58px) rotateZ(-16deg);
        box-shadow: 0 2px 5px rgba($gray, 0.5);
      }
      &.second {
        transform: translateY(-4px) translateX(-28px) rotateZ(-8deg);
        box-shadow: 0 2px 5px rgba($gray, 0.5);
      }
      &.third {
        transform: translateY(-4px);
      }
    }
    & .product-buy {
      border: 1px solid $black;
    }
    & .product-buy-border {
      background: $black;
      // border-left: 1px solid $black;
    }
  }
}
.product-title {
  display: flex;
  font-size: $f2;
  align-items: center;
}
.product-title-dot {
  margin: 0 10px 0 10px;
  color: $another-gray;
  font-size: 25px;
}
.product-title-gray {
  // color: $another-gray;
  font-size: 12px;
  text-transform: uppercase;
}
.product-preview {
  margin-top: 24px;
  width: 198px;
  height: 277px;
  position: relative;
}
.product-image {
  position: absolute;
  width: 198px;
  height: 277px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 14px;
  transition: all 375ms $ease-std;
  &.third {
    box-shadow: 0 2px 4px rgba($gray, 0.5);
  }
}
.product-buy {
  display: flex;
  align-items: center;
  margin-top: 15px;
  height: 29px;
  color: $black;
  font-size: $f2;
  text-decoration: none;
  border: 1px solid $border-gray;
  border-radius: 2px;
  line-height: 1 !important;
  transition: border 320ms ease;
}
.product-buy-curr {
  margin-right: -2px;
  margin-left: 8px;
  margin-bottom: -0.5px;
  color: $another-gray;
  font-size: 10.76px;
}
.product-buy-price {
  display: inline-flex;
  align-items: center;
  padding: 7px 12px 7px 12px;
}
.product-buy-text {
  margin-bottom: -1px;
  display: inline-block;
  padding: 7px 11px 9px 13px;
  font-size: $f2;
  transition: all 320ms ease;
}
.product-buy-border {
  width: 1px;
  height: 100%;
  background: $border-gray;
  transition: background 320ms ease;
}
.product-disabled {
  padding: 8px 11px 8px 11px;
  margin-top: 27.88px;
  color: $another-gray;
  font-size: $f2;
  text-decoration: none;
  border: 1px solid $border-gray;
  border-radius: 2px;
}
.product-countdown {
  position: absolute;
  top: calc(50% - 30px);
  left: 0;
  right: 0;
  z-index: 2;
  text-align: center;
  & span {
    color: #fff !important;
    font-size: 18px;
    line-height: 1.2;
    text-shadow: 0 1px 4px rgba(#000, 0.04);
  }
  & .days {
    color: #fff;
  }
  & .hours {
    color: #fff;
  }
  & .minutes {
    color: #fff;
  }
}
.product-tba {
  position: absolute;
  color: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.4;
  text-shadow: 0 1px 4px rgba(#000, 0.04);
  z-index: 2;
}
.product-quantity {
  cursor: auto;
  overflow: hidden;
  margin-top: -17px;
  width: 144px;
  opacity: 0;
  color: $another-gray;
  font-size: $f2;
  align-items: center;
  transition: margin 320ms $ease-std, opacity 320ms $ease-std;
}
.product-quantity-input {
  outline: none;
  padding: 5px 8px;
  width: 43px;
  font-size: $f3;
  text-align: right;
  background: #f8f8f8;
  text-align: center;
  border: 1px solid $border-gray;
  border-radius: 2px;
  transform: translateX(19px);
}

.about {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  pointer-events: none;
  visibility: hidden;

  transition: all 375ms $ease-std;
  &.visible {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }
}
.about-inner {
}
.about-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & p {
    margin-bottom: 1.5rem;
    font-size: $f2;
    line-height: 1.3;
  }
  & b {
    font-weight: 600;
  }
}
.about-scrollable {
  // overflow-y: scroll;
  padding: 0;
  max-width: 481px;
  max-height: 57vh;
}

.about-misc {
}

.about-header {
  display: inline-block;
}

.about-description {
  & b {
    display: block;
    padding-bottom: 6px;
    color: $black;
  }
  & p {
    padding-bottom: 1.7em;
    color: $another-gray;
    line-height: 1.4;
  }
  & a,
  a:visited {
    cursor: pointer;
    color: $another-gray;
    text-decoration: none;
    transition: color 200ms $ease-std;
    &:hover {
      color: #676767;
    }
  }
}
.about-close {
  cursor: pointer;
  margin-top: -345px;
  margin-left: 30px;
  width: 32px;
  height: 32px;
  background-size: contain;
  transition: opacity 200ms $ease-std;
  &:hover {
    opacity: 0.7;
  }
}
.about-countdown {
  padding-left: 60px;
}

.amba {
  @extend .about;
  font-size: 13px;
}
.amba-form {
  margin: 0 auto;
  padding-right: 20px;
  overflow-y: scroll;
  max-width: 500px;
  width: calc(100% - 40px);
  max-height: 420px;
}
.amba-radio {
  & label {
    cursor: pointer;
    color: $wolf-gray;
    margin-right: 15px;
    padding: 10px 15px;
    display: inline-block;
    line-height: normal;
    border: 1px solid $border-gray;
    transition: 120ms ease all;
    border-radius: 2px;
  }
  & input[type='radio'] {
    display: none;
  }
  & input[type='radio']:checked + label {
    color: $black;
    border: 1px solid $black;
  }
}
.amba-radiogroup {
  display: flex;
}
.amba-form__section {
  padding-bottom: 30px;
  width: 100%;
  & > div {
    padding-bottom: 10px;
    line-height: 1.5;
  }
  & input[type='text'],
  textarea {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid $border-gray;
    border-radius: 2px;
    transition: 120ms ease all;
    &:focus {
      outline: 0;
      border: 1px solid $black;
    }
  }
  & textarea {
    font-size: 13px;
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
    resize: vertical;
  }
}
.amba-submit {
  cursor: pointer;
  padding: 10px 15px;
  line-height: normal;
  width: 100%;
  border-radius: 2px;
  text-align: center;
  transition: 120ms ease all;
  background: $border-gray;
  border: 0;
  &:hover {
    background: $gray;
  }
}

.footer {
  position: absolute;
  bottom: 41px;
  left: 0;
  right: 0;
  display: flex;
  color: $another-gray;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer-navigation {
  margin-top: 12px;
  display: flex;
  align-items: center;
  color: $another-gray;
  flex-direction: row;
  & a,
  a:visited {
    cursor: pointer;
    margin: 0 8px;
    color: $another-gray;
    font-size: $f3;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 200ms $ease-std;
  }
  & a:hover {
    color: #676767;
  }
}
#nomobile {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
.nomobile-image {
  margin-bottom: 24px;
  width: (725px / 5);
  height: (86px / 5);
}

@media (max-width: 600px) {
  // #root {
  //   display: none;
  // }
  // #nomobile {
  //   display: flex !important;
  // }
  .footer-navigation li:first-child {
    // display: none;
  }
  .about-scrollable {
    padding: 0 2rem;
    overflow-y: scroll;
  }
  .stamp {
    margin-right: -110px;
    margin-bottom: -110px;
  }
}

@media (max-height: 580px) {
  // Mobile input
  .logo {
    display: none;
  }
  .footer-navigation {
    display: none;
  }
}
